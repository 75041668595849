
export default {
  name: 'AtomVideoSliderMatterportSpace',
  props: {
    sliderVideo: {
      required: true,
      type: Object,
      default: () => {
        return {
          video: ''
        }
      }
    }
  },
  computed: {
    cdnBase() {
      return this.$store.getters.cdnBase
    },
    isHostedVideo() {
      return !(!this.assetUrl.includes('youtube') && !this.assetUrl.includes('vimeo'))
    },
    assetUrl() {
      if (this.sliderVideo.video.url.includes('://')) {
        if (this.sliderVideo.video.url.includes('youtube')) {
          return this.sliderVideo.video.url + '?enablejsapi=1' // we need to add this to be able to stop/pause a youtube video programmatically.
        }
        return this.sliderVideo.video.url
      }
      return `${this.cdnBase}/${this.sliderVideo.video.url}`
    }
  },
  methods: {
    resourceUrl(img) {
      if (img.includes('://')) {
        return img
      }
      return `${this.$store.getters.cdnBase}/${img}`
    }
  }
}
